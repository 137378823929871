<script setup lang="ts">
import type { NuxtError } from "#app"

const errorId = computed(() => crypto.randomUUID())

defineProps<{ error: NuxtError }>()

const { t } = useI18n()
useHead({
  bodyAttrs: {
    class: "relative min-h-svh w-full overflow-x-hidden bg-slate-300/30",
  },
  title: computed(() => `Moxso – ${t("error_page.title")}`),
  link:
    process.env.NODE_ENV === "development"
      ? [{ rel: "icon", type: "image/ico", href: "/dev/favicon.ico" }]
      : [{ rel: "icon", type: "image/ico", href: "/favicon.ico" }],
})

const { fetchUser } = useAuth()
await useAsyncData("fetchUser", async () => {
  try {
    // fetchUser so VideoFeatured works
    await fetchUser()
  } catch {
    // intentionally left blank
  }
})
</script>
<template>
  <div class="flex min-h-svh w-full flex-col">
    <nav class="bg-slate-900">
      <div
        class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 py-2 sm:px-6 lg:px-8 lg:py-2.5"
      >
        <div
          class="relative flex shrink-0 flex-row items-center justify-center"
        >
          <NuxtLink to="/">
            <LogoSvg class="h-8" />
          </NuxtLink>
        </div>
        <div class="ml-10 flex items-baseline space-x-4">
          <FormButton variant="dark" to="/">
            {{ $t("error_page.go_back") }}
          </FormButton>
        </div>
      </div>
    </nav>
    <LayoutContainer>
      <div class="flex flex-col gap-6">
        <InfoCard image="/img/cards/general-error-card-bg.jpg">
          <template #title>
            {{ $t("error_page.title") }}
          </template>
          <div class="flex h-full flex-col justify-between">
            <div>
              {{ $t("error_page.text") }}
              <div class="w-fit">
                <FormButton class="my-4 block!" to="/" variant="black">
                  {{ $t("error_page.go_back") }}
                </FormButton>
              </div>
            </div>
            <div class="text-xs whitespace-pre-line">
              {{
                $t("error_page.error_has_been_reported", {
                  errorId,
                })
              }}
            </div>
          </div>
        </InfoCard>
        <VideoFeatured />
      </div>
    </LayoutContainer>
  </div>
</template>
