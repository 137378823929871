import { defineNuxtPlugin, useRuntimeConfig } from "#app"
import type { FpjsVueOptions } from "@fingerprintjs/fingerprintjs-pro-vue-v3"
import {
  fpjsPlugin,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-vue-v3"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.fingerprintClientApiKey,
      endpoint: [
        "https://moxso.com/aih2sygq5GP8wjer/CICqnXhQq2DpcdfV?region=eu",
        FingerprintJSPro.defaultEndpoint,
      ],
      scriptUrlPattern: [
        "https://moxso.com/aih2sygq5GP8wjer/9maTs3JvIbH4jjEH?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
        FingerprintJSPro.defaultScriptUrlPattern,
      ],
      region: "eu",
    },
  } as FpjsVueOptions)
})
