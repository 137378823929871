import * as amplitude from "@amplitude/analytics-browser"
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const { hostname } = useRequestURL()
  const subdomain = hostname.split(".")[0]
  const disableAnalytics = import.meta.dev || subdomain == "cancer"
  const { session } = useAuth()
  if (!disableAnalytics) {
    amplitude.init(
      runtimeConfig.public.amplitudeApiKey,
      session.value?.user?.id ?? undefined,
      {
        serverZone: "EU",
        trackingOptions: {
          ipAddress: false,
          language: true,
          platform: true,
        },
        defaultTracking: true,
      },
    )
  }
  const route = useRoute()
  const referrer = computed(() => route.query.referrer?.toString())
  const placement = computed(() => route.query.placement?.toString())
  return {
    provide: {
      trackEvent: (...args: Parameters<typeof amplitude.track>) => {
        if (disableAnalytics) {
          return
        }
        args[0] = `hub_${args[0]}`
        const disableRouteTracking = !!args[1]?.["disable_route_tracking"]
        if (disableRouteTracking && args[1]) {
          delete args[1]["entry_point"]
          delete args[1]["entry_placement"]
          delete args[1]["disable_route_tracking"]
        }
        if (referrer && !disableRouteTracking) {
          args[1] = { ...args[1], entry_point: referrer.value }
        }
        if (placement && !disableRouteTracking) {
          args[1] = { ...args[1], entry_placement: placement.value }
        }
        amplitude.track(...args)
      },
    },
  }
})
