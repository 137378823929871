import revive_payload_client_CthiV_qUsJztS2iTXJzmFJwjPbTiIkPQchdAWQalYCI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3kBhZGGv_bV13O7NvpJZhKQi6SW9V_l1abGjZ7wZTKE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_j3E29LdZNtfzh6RVGaBYobvowrjg7Im6lBUWzGg1H_U from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fGNqtnpIu6dEteORMJ6Mmgsg194KVGfY9W_uPBrJNTs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JqpRjgyLpemqtfnhtLvp34ySp3VH3bTd4OB76fpvztI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_iYTDWW6B3y1vhBXoWzB2webJmjSAV4oZYQg2e9nqGxQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_svo9eZRnl9YmYIZUG9iMAVCEE2FcxzkaJux17YADN5I from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/app/hub/.nuxt/components.plugin.mjs";
import prefetch_client_uvy_jo3jNJandybZWstRUDyWpE3Q7qa3mlxwvtXNjIY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_pe4z6yorebumog2immb6wqakbq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_uLZlZUtIDgqJnPW6zWQxQdaa6ontnLHZtM1vrPtTvaY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_chiyq6tsyb3hjfx7wmgwl7lg2e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_cQBFW2LrSBCtg4dSSsXkP7_OEuE4oCMiGLJW_CPnd0Q from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_chiyq6tsyb3hjfx7wmgwl7lg2e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_m4LsEzavd6VE976ABwEFkPBLGLuoB8RrPxyvrY2dcEA from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_chiyq6tsyb3hjfx7wmgwl7lg2e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import amplitude_esnxquZoSotPNSGidPs4e3Stc_nzmtQPbPwCNgwt3uU from "/opt/buildhome/repo/app/hub/plugins/amplitude.ts";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/opt/buildhome/repo/app/hub/plugins/api.ts";
import bugsnagReporter_ZAqLJ0JdXEDAVCUklS7tkyHeFe0Vo0CgF2yoUkTc_xI from "/opt/buildhome/repo/app/hub/plugins/bugsnagReporter.ts";
import fingerprint_client_rlGD_tNP6D_Gaz_2XdNo65ZK6AunNolsX0lSHLLohns from "/opt/buildhome/repo/app/hub/plugins/fingerprint.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/opt/buildhome/repo/app/hub/plugins/sentry.client.ts";
import ssg_detect_26VyYnhBFsjB2MRdv_Ovn7_2114aZ2NjmD36SSO4u44 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_chiyq6tsyb3hjfx7wmgwl7lg2e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_CthiV_qUsJztS2iTXJzmFJwjPbTiIkPQchdAWQalYCI,
  unhead_3kBhZGGv_bV13O7NvpJZhKQi6SW9V_l1abGjZ7wZTKE,
  router_j3E29LdZNtfzh6RVGaBYobvowrjg7Im6lBUWzGg1H_U,
  payload_client_fGNqtnpIu6dEteORMJ6Mmgsg194KVGfY9W_uPBrJNTs,
  navigation_repaint_client_JqpRjgyLpemqtfnhtLvp34ySp3VH3bTd4OB76fpvztI,
  check_outdated_build_client_iYTDWW6B3y1vhBXoWzB2webJmjSAV4oZYQg2e9nqGxQ,
  chunk_reload_client_svo9eZRnl9YmYIZUG9iMAVCEE2FcxzkaJux17YADN5I,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_uvy_jo3jNJandybZWstRUDyWpE3Q7qa3mlxwvtXNjIY,
  switch_locale_path_ssr_uLZlZUtIDgqJnPW6zWQxQdaa6ontnLHZtM1vrPtTvaY,
  route_locale_detect_cQBFW2LrSBCtg4dSSsXkP7_OEuE4oCMiGLJW_CPnd0Q,
  i18n_m4LsEzavd6VE976ABwEFkPBLGLuoB8RrPxyvrY2dcEA,
  amplitude_esnxquZoSotPNSGidPs4e3Stc_nzmtQPbPwCNgwt3uU,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  bugsnagReporter_ZAqLJ0JdXEDAVCUklS7tkyHeFe0Vo0CgF2yoUkTc_xI,
  fingerprint_client_rlGD_tNP6D_Gaz_2XdNo65ZK6AunNolsX0lSHLLohns,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  ssg_detect_26VyYnhBFsjB2MRdv_Ovn7_2114aZ2NjmD36SSO4u44
]