<script setup lang="ts">
// import SvgoCheckmark from "~ui/assets/icons/checkmark.svg?skipsvgo"
interface VideoProps {
  video: {
    image?: string
    duration: number
    title: string
    id: string
  }
  category?: string
  referrer?: string
  placement?: string
}

const props = defineProps<VideoProps>()
const { session } = useAuth()
const isWatched = computed(() =>
  session.value?.watched_videos?.includes(props.video.id),
)

const wrapperComponent = computed(() => {
  return props.category ? resolveComponent("NuxtLink") : "button"
})
const wrapperProps = computed(() => {
  return props.category
    ? {
        to: {
          name: "videos-category-video-id",
          params: {
            category: camelToKebab(props.category),
            id: props.video.id,
          },
          query: { referrer: props.referrer, placement: props.placement },
        },
      }
    : undefined
})

const { data: mandatoryContent } = await useCachedFetch<Mandatory>(
  "/mandatory/data",
  { method: "post", lazy: true, dedupe: "defer" },
)

const isMandatory = computed(() => {
  return Boolean(
    mandatoryContent.value?.videos.find(
      (mandatoryVideo) => mandatoryVideo.content.id == props.video.id,
    ),
  )
})
</script>
<template>
  <component :is="wrapperComponent" v-bind="wrapperProps">
    <LayoutCard
      :gutter="false"
      class="group mb-0! h-full overflow-hidden text-left"
    >
      <div
        class="transition-duration-300 relative w-full overflow-hidden bg-slate-200 pb-[56.15%] transition-opacity group-hover:opacity-85"
      >
        <img
          :src="video.image ?? '/img/placeholder.jpg'"
          class="absolute top-0 left-0 h-auto w-full max-w-full rounded-t-lg transition-all"
          :alt="video.title"
        />
        <div class="absolute top-0 left-0 w-full pb-[56.15%] drop-shadow-xs" />
        <VideoDuration :duration="video.duration" />
        <Badge
          v-if="isWatched && isMandatory"
          class="absolute top-1 right-1 flex flex-row items-center justify-start"
        >
          <template #icon><SvgoPikaRefresh /></template>
          {{ $t("mandatory.retake") }}
        </Badge>
        <Checkmark
          v-else-if="isWatched"
          class="absolute top-2 right-2 h-5 w-5 flex-none"
        />
      </div>
      <div class="px-4 py-2">
        <div data-testid="videocard_title" class="font-medium text-slate-700">
          {{ video.title }}
        </div>
      </div>
    </LayoutCard>
  </component>
</template>
