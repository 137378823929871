// Taken but modified from https://nuxt.com/docs/guide/recipes/custom-usefetch
import { FetchError } from "ofetch"
import { captureException, setContext } from "@sentry/vue"
function isObjectWithUnknownValues(
  value: unknown,
): value is { [key: string]: unknown } {
  return typeof value === "object" && value !== null && !Array.isArray(value)
}
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  const fetcher = $fetch.create({
    baseURL: runtimeConfig.public.apiBase,
    credentials: import.meta.client ? "include" : undefined,
    async onRequestError(context) {
      if (isObjectWithUnknownValues(context)) {
        setContext("error_context", context)
      }
      if (context.error) {
        captureException(context.error)
      } else {
        captureException("Unknown fetch request error")
      }
    },

    async onRequest({ options }) {
      const csrf = useCookie("XSRF-TOKEN")

      const { token } = useAuth()
      if (import.meta.server) {
        // Cloudflare workers crashes if this is set on server side
        // See https://github.com/cloudflare/workers-sdk/issues/2514
        options.credentials = undefined
      }
      const headers = (options.headers ??= new Headers())

      if (Array.isArray(headers)) {
        if (token.value) {
          headers.push(["Authorization", `Bearer ${token.value}`])
        }
        if (csrf.value) {
          headers.push(["X-XSRF-TOKEN", csrf.value])
        }
        if (
          options.method != "get" &&
          !headers.some((header) => header[0].toLowerCase() === "accept")
        ) {
          headers.push(["Accept", "application/json"])
        }
      } else if (headers instanceof Headers) {
        if (token.value) {
          headers.set("Authorization", `Bearer ${token.value}`)
        }
        if (csrf.value) {
          headers.set("X-XSRF-TOKEN", csrf.value)
        }
        if (options.method != "get" && !headers.has("Accept")) {
          headers.set("Accept", "application/json")
        }
      }
    },
    async onResponseError(context) {
      /** List of endpoints where we ignore 404 errors. */
      // Would be nicer if `$api` took an argument about whether to ignore 404
      // errors. Can't think of a way to make that work though.
      const ignored404Endpoints = [
        "/auth/login", // 404 if user types wrong email
      ]

      const request = context.request
      const shouldIgnore404InSentry = ignored404Endpoints.some((endpoint) => {
        // `request` is either a `string` or `Request`
        if (typeof request == "string") {
          return request.endsWith(endpoint)
        }
        return request.url.endsWith(endpoint)
      })
      const ignoredSentryErrorCodes: number[] = [
        419, // CSRF Token expired or invalid. If this happens we'll just try again
        429, // Rate limit. We'll get that sometimes on the `points` endpoint, but we can ignore the error
        ...(shouldIgnore404InSentry ? [404] : []), // add 404 to ignored error codes if needed
      ]
      const response = context.response

      const { logout } = useAuth()

      if (response.status === 401) {
        await logout()
      } else if (
        response.redirected &&
        response.headers.get("location")?.includes("/login")
      ) {
        await logout()
      } else if (!ignoredSentryErrorCodes.includes(response.status)) {
        if (isObjectWithUnknownValues(context)) {
          setContext("error_context", context)
        }
        if (isObjectWithUnknownValues(response)) {
          setContext("error_context", response)
        }
        setContext("status_code", { code: response.status })

        const error = context.error
        if (!error) {
          captureException(new Error(`${response.status}: Unknown fetch error`))
        } else {
          captureException(error)
        }
      }
    },
  })

  /** Fetches with authentication, proper csrf token and accept headers.
   * Retries after getting CSRF error.
   * The API usually works without an accept header, but if the token is invalid it will return a 302 instead of 401. */
  const api = async function api<T>(...a: Parameters<typeof fetcher<T>>) {
    try {
      // if an error happens, we always want to know the URL.
      // Sometimes, for example when getting `TypeError: Failed to fetch` errors,
      // we can't see the context in Sentry. This should fix that.
      const request = a[0]
      const url = typeof request == "string" ? request : request.url
      setContext("request_url", { url })

      return await fetcher<T>(...a)
    } catch (e) {
      const isCsrfError = e instanceof FetchError && e?.response?.status == 419
      if (isCsrfError) {
        await fetcher(`/auth/csrf`, { credentials: "include" })
        return await fetcher<T>(...a)
      }
      throw e
    }
  } as typeof $fetch

  // The `$fetch` option for `useFetch` is typed as `$Fetch`/`typeof $fetch`
  // If we don't set these, the types won't be correct.
  // ofetch does the same thing: https://github.com/unjs/ofetch/blob/9cf16e098e8ebfe3e353e274b4bc676d2d39f845/src/fetch.ts#L239
  api.raw = fetcher.raw
  api.create = fetcher.create

  return {
    provide: {
      api,
    },
  }
})
