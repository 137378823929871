
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSR2bPq3pOn9_45Ewy087_45VKhHPGOce_i0gwJaHpn2CqYAMeta } from "/opt/buildhome/repo/app/hub/pages/index.vue?macro=true";
import { default as logout1eQaPMSd4DPc19duWVXIYhOpcaoSub_452T5nhq6ZAE4MMeta } from "/opt/buildhome/repo/app/hub/pages/logout.vue?macro=true";
import { default as index_45ADt_Bh_4isYptaHtD7JGrgXy9aNABSDOGb8ygA6_VIMeta } from "/opt/buildhome/repo/app/hub/pages/videos/index.vue?macro=true";
import { default as indexTfKH1WRIMQnn2unlvywc5G8Lraf0aX1naNVNS4cdQioMeta } from "/opt/buildhome/repo/app/hub/pages/videos/[category]/index.vue?macro=true";
import { default as _91id_93eMpdI_SgLhedQxq8WTHJvpLbrkQZ9nA8F531Ml_NAyQMeta } from "/opt/buildhome/repo/app/hub/pages/videos/[category]/video/[id].vue?macro=true";
import { default as videosbNtjNUZrFVkavLjgY_45wgYbNjKz5idUJqbjSSgZYo_bUMeta } from "/opt/buildhome/repo/app/hub/pages/videos.vue?macro=true";
import { default as indexL_pZJwjQSTwmKQ3u6Rovb2FTcTe8pN4VgohDZqwu7BcMeta } from "/opt/buildhome/repo/app/hub/pages/courses/index.vue?macro=true";
import { default as indexF6ubd_D7XcbJsSH1ekiZhuja6LavXfeVATPHFkwUP3YMeta } from "/opt/buildhome/repo/app/hub/pages/courses/[courseId]/index.vue?macro=true";
import { default as coursesZmcS_45OiFrbunbV7I0QZlcS5WJZi2vsKgCcvEspWm328Meta } from "/opt/buildhome/repo/app/hub/pages/courses.vue?macro=true";
import { default as breachesZHxBtK58ByeiT_zAGKlojhZ7_KE_45MGcOOvKC3r0xOhkMeta } from "/opt/buildhome/repo/app/hub/pages/breaches.vue?macro=true";
import { default as linkDbCJLLYZirc3GI540BWH_XyKyJX6EaKTXihP2o0cXR4Meta } from "/opt/buildhome/repo/app/hub/pages/login/link.vue?macro=true";
import { default as index4GAyvD4ZtpmcqfCVfEwFZpLOfsbKzcFsMQm1jY5L0p4Meta } from "/opt/buildhome/repo/app/hub/pages/quiz/index.vue?macro=true";
import { default as indexxEwRllOACXsLfsC8LtkMuwROjEwPo_45NsL7nyBJxCap0Meta } from "/opt/buildhome/repo/app/hub/pages/login/index.vue?macro=true";
import { default as simulations_456Trrroki_45Hs6_xGOgaUcSbJCwg_m6jEeWNSGpnYOLgMeta } from "/opt/buildhome/repo/app/hub/pages/simulations.vue?macro=true";
import { default as _91provider_93hpwB_45q2j7apj32ZWJfjzx0Z8NFp_455qSwSAa0_45K6Ljf4Meta } from "/opt/buildhome/repo/app/hub/pages/sso/[provider].vue?macro=true";
import { default as indexY18WcOpE1z05hRUjae_MSVWnGqUa_Fr7SQiZJcNAxHMMeta } from "/opt/buildhome/repo/app/hub/pages/onboarding/index.vue?macro=true";
import { default as _91policyId_93XnIzFlyXj252WW5hFL3Pp0h6AM_45RrEB6f4IPcoDkZAMMeta } from "/opt/buildhome/repo/app/hub/pages/policies/[policyId].vue?macro=true";
import { default as quizHFr8vpkr7ATRjfxG_gn9RftfZAUtTIIvA_453bfD3AyooMeta } from "/opt/buildhome/repo/app/hub/pages/onboarding-quiz/quiz.vue?macro=true";
import { default as indexF3y2hBCDle7NoFRSt9cTmBGZh6LY8Qw5tK9foIRPfEAMeta } from "/opt/buildhome/repo/app/hub/pages/onboarding-quiz/index.vue?macro=true";
import { default as finishedHyXWDg6x5Nncjercchnc6Wn_453t7uZkOliqGd77ZtirkMeta } from "/opt/buildhome/repo/app/hub/pages/onboarding-quiz/finished.vue?macro=true";
import { default as _91simulationId_93rCsDntr991dZ56RIoF3urkOfnyd12y8scZHVcfskXOsMeta } from "/opt/buildhome/repo/app/hub/pages/click/[employeeId]/[simulationId].vue?macro=true";
import { default as _91simulationId_93UagC051hyvC8E_45T8TvMAXYZv_45In_GwwceeCN74_45Kwu4Meta } from "/opt/buildhome/repo/app/hub/pages/submission/[employeeId]/[simulationId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/hub/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout1eQaPMSd4DPc19duWVXIYhOpcaoSub_452T5nhq6ZAE4MMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/logout.vue")
  },
  {
    name: videosbNtjNUZrFVkavLjgY_45wgYbNjKz5idUJqbjSSgZYo_bUMeta?.name,
    path: "/videos",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos.vue"),
    children: [
  {
    name: "videos",
    path: "",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/index.vue")
  },
  {
    name: "videos-category",
    path: ":category()",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/[category]/index.vue")
  },
  {
    name: "videos-category-video-id",
    path: ":category()/video/:id()",
    component: () => import("/opt/buildhome/repo/app/hub/pages/videos/[category]/video/[id].vue")
  }
]
  },
  {
    name: coursesZmcS_45OiFrbunbV7I0QZlcS5WJZi2vsKgCcvEspWm328Meta?.name,
    path: "/courses",
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses.vue"),
    children: [
  {
    name: "courses",
    path: "",
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses/index.vue")
  },
  {
    name: "courses-courseId",
    path: ":courseId()",
    meta: indexF6ubd_D7XcbJsSH1ekiZhuja6LavXfeVATPHFkwUP3YMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/courses/[courseId]/index.vue")
  }
]
  },
  {
    name: "breaches",
    path: "/breaches",
    component: () => import("/opt/buildhome/repo/app/hub/pages/breaches.vue")
  },
  {
    name: "login-link",
    path: "/login/link",
    meta: linkDbCJLLYZirc3GI540BWH_XyKyJX6EaKTXihP2o0cXR4Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/login/link.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: index4GAyvD4ZtpmcqfCVfEwFZpLOfsbKzcFsMQm1jY5L0p4Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/quiz/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexxEwRllOACXsLfsC8LtkMuwROjEwPo_45NsL7nyBJxCap0Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/login/index.vue")
  },
  {
    name: "simulations",
    path: "/simulations",
    component: () => import("/opt/buildhome/repo/app/hub/pages/simulations.vue")
  },
  {
    name: "sso-provider",
    path: "/sso/:provider()",
    meta: _91provider_93hpwB_45q2j7apj32ZWJfjzx0Z8NFp_455qSwSAa0_45K6Ljf4Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/sso/[provider].vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexY18WcOpE1z05hRUjae_MSVWnGqUa_Fr7SQiZJcNAxHMMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/onboarding/index.vue")
  },
  {
    name: "policies-policyId",
    path: "/policies/:policyId()",
    meta: _91policyId_93XnIzFlyXj252WW5hFL3Pp0h6AM_45RrEB6f4IPcoDkZAMMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/policies/[policyId].vue")
  },
  {
    name: "onboarding-quiz-quiz",
    path: "/onboarding-quiz/quiz",
    meta: quizHFr8vpkr7ATRjfxG_gn9RftfZAUtTIIvA_453bfD3AyooMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/onboarding-quiz/quiz.vue")
  },
  {
    name: "onboarding-quiz",
    path: "/onboarding-quiz",
    meta: indexF3y2hBCDle7NoFRSt9cTmBGZh6LY8Qw5tK9foIRPfEAMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/onboarding-quiz/index.vue")
  },
  {
    name: "onboarding-quiz-finished",
    path: "/onboarding-quiz/finished",
    meta: finishedHyXWDg6x5Nncjercchnc6Wn_453t7uZkOliqGd77ZtirkMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/onboarding-quiz/finished.vue")
  },
  {
    name: "click-employeeId-simulationId",
    path: "/click/:employeeId()/:simulationId()",
    meta: _91simulationId_93rCsDntr991dZ56RIoF3urkOfnyd12y8scZHVcfskXOsMeta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/click/[employeeId]/[simulationId].vue")
  },
  {
    name: "submission-employeeId-simulationId",
    path: "/submission/:employeeId()/:simulationId()",
    meta: _91simulationId_93UagC051hyvC8E_45T8TvMAXYZv_45In_GwwceeCN74_45Kwu4Meta || {},
    component: () => import("/opt/buildhome/repo/app/hub/pages/submission/[employeeId]/[simulationId].vue")
  }
]