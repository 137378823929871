<script setup lang="ts">
const {
  status,
  data: videos,
  error,
} = await useCachedFetch<Video[]>("/videos/featured", {
  method: "post",
  lazy: true,
})

const { hasFeature } = useAuth()
</script>
<template>
  <div v-if="hasFeature('hub-videos')">
    <div class="mb-4 flex justify-between">
      <TypographyHeading
        :level="3"
        class="text-lg leading-6 font-bold text-slate-800"
      >
        {{ $t("videos.latest") }}
      </TypographyHeading>
      <NuxtLink
        to="/videos"
        class="font-medium text-blue-500 hover:text-blue-800"
        >{{ $t("videos.more") }}</NuxtLink
      >
    </div>
    <LayoutCardListLoader :count="3" :loading="status == 'pending'">
      <template v-for="video in videos" :key="video.id">
        <VideoCard
          v-if="video.category"
          :category="video.category"
          :video
          referrer="overview"
          placement="featured"
        />
      </template>
    </LayoutCardListLoader>
  </div>
  <LocalisedAlert :error />
</template>
